<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="640" hide-overlay>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Settings </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="settings.presetDuration" label="Preset duration" suffix="s" :rules="[rules.isNum]"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="settings.blendDuration" label="Blend duration" suffix="s" :rules="[rules.isNum]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="settings.secondDelayBetweenAnimations" label="Delay between animations" suffix="s" :rules="[rules.isNum]"></v-text-field>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <v-text-field v-model="settings.mainText" label="Main text" disabled />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="settings.additionalText" label="Additional text" clearable />
            </v-col>
          </v-row> -->

          <!-- <v-row>
            <v-col cols="7">
              <v-row>
                <v-col>
                  <v-slider thumb-label label="Text size" min="30" max="115" step="5" v-model="settings.scrollSize" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider thumb-label label="Text top" min="0" max="100" step="1" v-model="settings.textTop" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider thumb-label v-model="settings.scrollDuration" label="Animation duration" min="10" max="60" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              Text color
              <v-color-picker v-model="settings.scrollColor" hide-inputs></v-color-picker>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col>
              <v-checkbox v-model="settings.show" label="Show preset info" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onClose"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { saveSettings } from '@/utils/settings'

export default {
  props: ['value'],
  data: () => ({
    dialog: false,
    settings: {},
    rules: {
      isNum: (v) => !isNaN(v) || 'Please enter valid number'
    }
  }),
  methods: {
    open () {
      this.dialog = true
    },
    onClose () {
      saveSettings(this.settings)
      this.dialog = false
    }
  },
  mounted () {
    this.settings = this.value
  }
}
</script>
