const key = 'AI-MUSIC-VIS'

function saveSettings (settings) {
  localStorage.setItem(key, JSON.stringify(settings))
}

function restoreSettings () {
  return JSON.parse(localStorage.getItem(key))
}

export {
  saveSettings,
  restoreSettings
}
