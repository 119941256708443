<template>
  <v-app>
    <div id="wrapper" ref="wrapper">
      <div id="visWrapper">
        <canvas id="visCanvas" class="vis-canvas" width="800" height="600" />
      </div>

      <div id="controlsWrapper">
        <div v-show="showZPAnim" id="zp-animation">
          <video id="video1" muted="muted">
            <source src="@/assets/animacija.webm" type="video/webm">
            Your browser does not support HTML video.
          </video>
        </div>
        <div id="settingsBtn">
          <v-menu top offset-y close-on-click close-on-content-click>
            <template v-slot:activator="{ on }">
              <v-btn dark icon v-on="on">
                <v-icon>menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="setFullscreen">Fullscreen</v-list-item>
              <v-list-item @click="showSettingsForm">Settings</v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div id="settings" v-if="settings.show">
          {{ presetName }} <br />
          Preset duration: {{ settings.presetDuration }}s | Blend duration: {{ settings.blendDuration }}s
        </div>
      </div>
    </div>

    <settings-dialog ref="settingsDialog" v-model="settings" />
  </v-app>
</template>

<script>
import butterchurn from 'butterchurn'
import butterchurnPresets from 'butterchurn-presets'
import SettingsDialog from '@/components/SettingsDialog'
import { restoreSettings } from '@/utils/settings'

export default {
  name: 'App',
  data: () => ({
    visualizer: undefined,
    presetLoader: undefined,
    fixedPreset: false,
    randomChange: true,
    presetName: undefined,
    showZPAnim: false,
    settings: {
      presetDuration: 15, // in seconds
      blendDuration: 5.7,
      show: false,
      mainText: 'Dobrodošli u café bar Palermo',
      additionalText: '',
      scrollSize: 70,
      scrollColor: '#c12246',
      scrollDuration: 10,
      textTop: 2,
      currentDelay: 0,
      delayBetweenAnimations: 5,
      secondDelayBetweenAnimations: 300
    }
  }),
  components: {
    SettingsDialog
  },
  methods: {
    init () {
      const settings = restoreSettings()
      if (settings !== null) {
        this.settings.presetDuration = settings.presetDuration
        this.settings.blendDuration = settings.blendDuration
        this.settings.show = settings.show
        this.settings.mainText = settings.mainText
        this.settings.additionalText = settings.additionalText
        this.settings.scrollSize = settings.scrollSize
        this.settings.scrollColor = settings.scrollColor
        this.settings.scrollDuration = settings.scrollDuration
        this.settings.textTop = settings.textTop
      }
    },
    getWrapper () {
      return document.getElementById('visWrapper')
    },
    getCanvas () {
      return document.getElementById('visCanvas')
    },
    getRandomInt (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    showSettingsForm () {
      this.$refs.settingsDialog.open()
    },
    loadPreset () {
      if (!this.fixedPreset && !this.presetLoader) this.presetLoader = setInterval(this.loadPreset, this.settings.presetDuration * 1000)

      const presets = butterchurnPresets.getPresets()

      const presetKeys = Object.keys(presets)

      const idx = this.getRandomInt(0, presetKeys.length - 1) // TODO: change this in case of sequential presets change instead of random
      this.presetName = presetKeys[idx]
      const preset = presets[this.presetName]

      this.visualizer.loadPreset(preset, this.settings.blendDuration) // 2nd argument is the number of seconds to blend presets
    },
    setSize () {
      const wrapper = this.getWrapper()
      const wrapperWidth = window.getComputedStyle(wrapper, null).getPropertyValue('width').replace('px', '')
      const wrapperHeight = window.getComputedStyle(wrapper, null).getPropertyValue('height').replace('px', '')

      const canvas = this.getCanvas()
      canvas.setAttribute('width', wrapperWidth)
      canvas.setAttribute('height', wrapperHeight)
      this.visualizer.setRendererSize(wrapperWidth, wrapperHeight)
    },
    setFullscreen () {
      const elem = this.$refs.wrapper
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen()
        }
      } else {
        document.exitFullscreen()
      }
    },
    render () {
      window.requestAnimationFrame(() => this.render())
      this.visualizer.render()
    },
    connectMicAudio (sourceNode, audioContext) {
      audioContext.resume()

      const gainNode = audioContext.createGain()
      gainNode.gain.value = 1.25
      sourceNode.connect(gainNode)

      this.visualizer.connectAudio(gainNode)
      this.render()
    },

    animationEndHandler () {
      this.showZPAnim = false
      this.showZPAnimation()
    },

    showZPAnimation () {
      setTimeout(() => {
        const animel = document.getElementById('zp-animation')
        const myVideo = document.getElementById('video1')
        myVideo.addEventListener('ended', this.animationEndHandler, false)
        const xpos = Math.floor(Math.random() * (screen.width - 540))
        const ypos = Math.floor(Math.random() * (screen.height - 580))
        animel.style.top = `${ypos}px`
        animel.style.left = `${xpos}px`
        myVideo.play()
        this.showZPAnim = true
        this.settings.currentDelay = this.settings.secondDelayBetweenAnimations
      }, this.settings.currentDelay * 1000)
    },
    async createVisualizer () {
      const canvas = this.getCanvas()
      const AudioContext = window.AudioContext || window.webkitAudioContext
      const audioContext = new AudioContext()

      this.visualizer = butterchurn.createVisualizer(audioContext, canvas, {
        width: 800,
        height: 600,
        pixelRatio: window.devicePixelRatio || 1,
        textureRatio: 1
      })

      this.setSize()

      // load a preset
      this.loadPreset()

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      // microphone can now act like any other AudioNode
      const microphone = audioContext.createMediaStreamSource(stream)
      this.connectMicAudio(microphone, audioContext)
    }
  },
  mounted () {
    this.init()
    this.createVisualizer()
    this.settings.currentDelay = this.settings.delayBetweenAnimations
    this.showZPAnimation()
    window.addEventListener('resize', this.setSize)
    window.addEventListener('dblclick', this.setFullscreen)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setSize)
    window.removeEventListener('dblclick', this.setFullscreen)
  }
}
</script>

<style>
html,
body,
#app,
#wrapper,
#visWrapper,
#controlsWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
}

#visWrapper,
#controlsWrapper {
  position: absolute;
}

#controlsWrapper {
  z-index: 100;
  background-color: transparent;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #000;
}

#settings {
  position: absolute;
  bottom: 0;
  right: 0;
  color: lime;
  font-family: monospace;
}

#wrapper:fullscreen #settingsBtn,
#wrapper:fullscreen #settings {
  display: none;
}

#settingsBtn {
  position: absolute;
  left: 0;
  bottom: 0;
}

#scroll-container {
  overflow: hidden;
  background: transparent;
  position: absolute;
  width: 100%;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 10s linear infinite;

  background: transparent;
  font-weight: bold;
  text-shadow: 0 0 15px #fff;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

#zp-animation {
  position: absolute;
  top: 100px;
  left: 100px;
  background: transparent;
  width: 400px;
  height: 400px;
}
</style>
